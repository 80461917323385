import React from "react";
import Vector from "../../images/dashboard/welcome-img.png";
import { Link } from 'react-router-dom';

function AiDesignPlatform() {
  return (
    <div className="xl:mt-20 mt-5 2xl:flex block bg-[#E5E7EBA6]  relative rounded-2xl xl:px-10 py-4 px-3">
      <div className="col">
        <div className="h-full flex flex-col justify-center">
          <h2 className="text-[42px] font-medium gradient-text font-garetExtraBold">
            AI Design Platform
          </h2>
          <p className="max-w-[664px] text-atomic-text-primary tracking-wide font-GaretRegular">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make.
          </p>
            <div className='hero-container'>
                {/* <button type='button' className='btn text-white btn-get-started'>Get Started</button> */}
                <Link to={'https://app.atomicinterior.com/generator'} style={{fontFamily: "garetExtraBold"}} className='btn text-white btn-get-started'> Open AI App</Link>
              </div>
        </div>
      </div>
      <div className="col">
        <img
          className="xl:absolute bottom-0 right-10 mx-auto"
          src={Vector}
          alt=""
        />
      </div>
    </div>
  );
}

export default AiDesignPlatform;
