import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AdminDashboard from '../AdminPortal/pages/Dashboard';
import AiDesignTool from '../AdminPortal/pages/AI-Design-Tool';
import AdminAnalytics from '../AdminPortal/pages/Analytics';
import APIConfiguration from '../AdminPortal/pages/API-Configuration';
import TaskPage from '../AdminPortal/pages/Tasks';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import AdminProtected from './AdminProtected';

function AdminRouter() {

  const navigate = useNavigate()
  const getUser = localStorage.getItem('userEmail')
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  

  useEffect(()=>{
          
        const unsubscribe = onAuthStateChanged(auth, authUser => {
                if(authUser){
                     navigate('/admin/dashboard');
                }
                // else{

                //      navigate('/')
                // }
        })
        return ()=>unsubscribe();

  },[]);

  return (
        <>
            <Routes>
                <Route path='/admin/dashboard' element={<AdminProtected><AdminDashboard/></AdminProtected> } />
                <Route path='/admin/ai-tool-design' element={<AdminProtected><AiDesignTool /></AdminProtected>} />
                <Route path='/admin/analytics' element={<AdminProtected><AdminAnalytics /></AdminProtected>} />
                <Route path='/admin/api-configuration' element={<AdminProtected><APIConfiguration /></AdminProtected>} />
                <Route path='/admin/tasks' element={<AdminProtected><TaskPage /></AdminProtected>} />
            </Routes>
        </>
  )
}

export default AdminRouter