import './SignIn.css';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { app } from '../../firebase';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/imgs/Atomic-Interior-Logo.svg';

const auth = getAuth(app);

const SignIn = () => {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPasword] = useState("");
    const [authState, setAuthState] = useState(false)
    const getUser = localStorage.getItem('userEmail')
    
  
    const signinUser =  () => {
         signInWithEmailAndPassword(auth, userEmail, userPassword)
        .then((response) => {
            
             
             localStorage.setItem('userEmail',response.user.email)
           navigate("/admin/dashboard");

        })
        .catch((error) => {
            console.log(error);
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        // try {
        //   let user = await login(event.target);
        //   this.setState({ user });
        // } catch (error) {
        //   this.setState({ error });
        // }
      }

//    if(!authState){
//      return (
//            <div>asdfasdfsadf</div>
//      )
//    }   
  return (
    <>
    {  <section className='main-signin-sec'>
        <div className="container-fuild">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-12">
                    <div className="singnin-box">
                        <div className='logo'>
                            <img src={logo} alt='Atomic Interiors Logo' className='img-fluid pb-5 mx-3' />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="userEmail" className="form-label">Email</label>
                                <input type="email" onChange={(e) => setUserEmail(e.target.value)} value={userEmail} className="form-control" id="userEmail" placeholder="name@example.com" />
                                </div>
                            <div className="mb-3">
                                <label htmlFor="userPassword" className="form-label">Password</label>
                                <input type="password" onChange={(e) => setUserPasword(e.target.value)} value={userPassword} className="form-control" id="userPassword" placeholder="password" />
                            </div>
                            <button type="submit" className="btn btn-login text-white" onClick={signinUser}>Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }</>
  )
}

export default SignIn;