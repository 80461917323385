import React from "react";
import Chart from "react-apexcharts";

const PieChart = () => {
  const chartData = {
    series: [55, 15, 8, 10],
    options: {
      labels: ["Las Vegas", "New York", "Chicago", "Other"],
      colors: ["#988171", "#988171", "#988171", "#988171"],
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          const percentage = (
            (val / w.globals.seriesTotals[seriesIndex]) *
            100
          ).toFixed(2);
          return `${w.config.labels[seriesIndex]}: ${percentage}%`;
        },
      },
      dataLabels: {
        enabled: false, // Hide the percentages from slices
      },
    },
  };
  return (
    <>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={"100%"}
        height={400}
      />
    </>
  );
};

export default PieChart;
