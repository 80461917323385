import React from "react";

import { BsFillArrowDownCircleFill, IoIosArrowDown } from "react-icons/io";

const AccordionLayout = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const handleSetIndex = (index) =>
    activeIndex !== index && setActiveIndex(index);

  return (
    <>
      <div
        onClick={() => handleSetIndex(index)}
        className="flex gap-3 w-full justify-start p-2 mt-2 rounded bg-[#f3f2f2] cursor-pointer"
      >
        <div className="flex w-[25px]">
          <IoIosArrowDown className="w-8 h-8" />
        </div>
        <div className="flex items-center justify-center w-full">
          <div className="flex justify-between items-center w-full ">
            <h5>{title}</h5>
            <div className="flex gap-3">
              <button className="border border-[#209a4a] text-[#209a4a] font-GaretRegular text-base font-medium px-10 py-1 bg-[#209A4A0D] rounded-md">
                Post
              </button>
              <button className="border border-[#988171] text-[#988171] font-GaretRegular text-base font-medium px-10 py-1 bg-[#855E420D] rounded-md">
                Get
              </button>
            </div>
          </div>
        </div>
      </div>

      {activeIndex === index && (
        <div className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
          {children}
        </div>
      )}
    </>
  );
};

export default AccordionLayout;
