export const UserData = [
  {
    id: 1,
    month: "Jun 8",
    visitors: 11900,
  },
  {
    id: 2,
    month: "Jun 15",
    visitors: 13000,
  },
  {
    id: 3,
    month: "Jun 22",
    visitors: 6000,
  },
  {
    id: 4,
    month: "Jun 29",
    visitors: 8000,
  },
  {
    id: 5,
    month: "Jul 6",
    visitors: 1200,
  },
  {
    id: 6,
    month: "Jul 13",
    visitors: 10000,
  },
];
