import './SignUp.css';
import emailjs from '@emailjs/browser';
import { useState, useRef } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { app, database } from '../../firebase';
import {ref,push,child,update} from "firebase/database";
import logo from '../../assets/imgs/Atomic-Interior-Logo.svg';
import { useNavigate } from 'react-router-dom';


const auth = getAuth(app);

    const SignUp = () => {

        const form = useRef();
        const navigate = useNavigate();

        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [isSending,setIsSending] = useState(false)
    
        const handleInputChange = (e) => {
            const {id , value} = e.target;
            if(id === "userFName"){
                setFirstName(value);
            }
            if(id === "userLName"){
                setLastName(value);
            }
            if(id === "userEmail"){
                setEmail(value);
            }
            if(id === "userPassword"){
                setPassword(value);
            }
            if(id === "confirmPassword"){
                setConfirmPassword(value);
            }
    
        }
    
        const handleSubmit  = () => {
            console.log(firstName, lastName, email, password, confirmPassword);
             setIsSending(true)
             createUserWithEmailAndPassword(auth, email, confirmPassword)
            .then((value) => {
                console.log(value);
                localStorage.setItem('useremail', value.user.email);
                setIsSending(false)
                emailjs.sendForm('service_pxkgr8e', 'template_gqrnyrk', form.current, 'ralpRif2RMF8HtrVf')
                .then((result) => {
                    console.log("email sent ==> ",result.text);
                    
                    const newUserKey = push(child(ref(database), 'users')).key;
                    const updates = {};
                    updates['/' + newUserKey] = obj
                    return update(ref(database), updates);
                })
                .catch(err=>{
                       console.log("error=> ",err)
                })
            }).catch(err=>{

                 console.log("error",err)
                 setIsSending(false)
            })

           

            let obj = {
                firstName : firstName,
                lastName: lastName,
                email: email,
                password: password,
                confirmPassword: confirmPassword,
            }   
          
        }
        // User Signup 
        // const signupUser = () => {
        //     createUserWithEmailAndPassword(auth, email, confirmPassword)
        //     .then((value) => {
        //         console.log(value);
        //         localStorage.setItem('useremail', value.user.email);
        //     });
        // };

    // Control Form
    const handlerSubmit = (event) => {
        event.preventDefault();
    }
  return (
    <section className='main-signup-sec'>
        <div className="container-fuild">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-12">
                    <div className="singnup-box">
                      <div className='logo'>
                          <img src={logo} alt='Atomic Interiors Logo' className='img-fluid pb-5 mx-3' />
                      </div>
                      <form ref={form} onSubmit={handlerSubmit}>
                        <div className="mb-3">
                            <label htmlFor="userFName" className="form-label">First Name</label>
                            <input type="text" className="form-control" id="userFName" placeholder="First Name" value={firstName} name="from_name" onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="userLName" className="form-label">Last Name</label>
                            <input type="text" className="form-control" id="userLName" placeholder="Last Name" value={lastName} onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="userEmail" className="form-label">Email</label>
                            <input type="email" className="form-control" id="userEmail" placeholder="name@example.com" value={email} name="user_email" onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="userPassword" className="form-label">Password</label>
                            <input type="password" className="form-control" id="userPassword" placeholder="Password" value={password} onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange = {(e) => handleInputChange(e)} />
                        </div>
                       
                            <button type="submit" className="btn btn-signup text-white" disabled={isSending} onClick={()=>handleSubmit()} >{isSending? 'Signing up' : 'Sign Up'}</button>
                            
                        
                      </form>
                    </div>
                </div>
            </div>
        </div>
        {/* <button onClick={signupUser}>Create User</button> */}
    </section>
  )
}

export default SignUp;