import Logo from "../images/sidebar/Logo.png";
import DashboardIcon from "../images/sidebar/dashboard.svg";
import AIDesignIcon from "../images/sidebar/ai-design.svg";
import AnalyticsIcon from "../images/sidebar/analytics.svg";
import APIIcon from "../images/sidebar/api.svg";
import TasksIcon from "../images/sidebar/task.svg";
import AdminIcon from "../images/sidebar/admin.svg";
import LogoutIcon from "../images/sidebar/logout.svg";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

function SideBar() {
  const DashboardMenu = [
    {
      id: 1,
      icon: DashboardIcon,
      menu: "Dashboard",
      path: "/admin/dashboard",
    },
    {
      id: 2,
      icon: AIDesignIcon,
      menu: "Al Design Tool",
      path: "/admin/ai-tool-design",
    },
    {
      id: 3,
      icon: AnalyticsIcon,
      menu: "Analytics Engine",
      path: "/admin/analytics",
    },
    {
      id: 4,
      icon: APIIcon,
      menu: "API Configuration",
      path: "/admin/api-configuration",
    },
    {
      id: 5,
      icon: TasksIcon,
      menu: "Tasks",
      path: "/admin/tasks",
    },
    {
      id: 6,
      icon: AdminIcon,
      menu: "Admin",
      path: "/admin/dashboard",
    },
  ];

  const navigate = useNavigate()

  const signOutHandler =()=>{
         
    signOut(auth).then(()=>{

      localStorage.removeItem('userEmail')
      navigate('/')
    })
    
  }

  return (
    <div className="sideBar relative max-w-[287px] bg-atomic-primary 2xl:p-8 p-4 flex  justify-between flex-col text-white h-screen">
      <div>
        <img src={Logo} alt="logo" />
        <div className="dashboardItems py-9">
          <ul className="flex flex-col gap-4">
            {DashboardMenu.map((item) => {
              return (
                <li key={item.id} className="">
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? "flex mr-2 gap-4 items-center text-sm menu-items py-2 2xl:px-4 px-3 bg-atomic-transparent-bg rounded-lg cursor-pointer relative after:content-[''] after:absolute after:h-[100%] after:w-[30px] after:bg-menu-after 2xl:after:-right-12 after:-right-8 after:top-0 after:bg-no-repeat hover:text-white"
                        : "flex gap-4 items-center text-sm menu-items py-2 2xl:px-4 px-3  rounded-lg cursor-pointer hover:text-white"
                    }
                  >
                    <img
                      className="bg-atomic-transparent-bg p-2 rounded-lg"
                      src={item.icon}
                      alt=""
                    />
                    <span className="hover:text-white font-GaretRegular">
                      {item.menu}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <img
          className="bg-atomic-transparent-bg p-2 rounded-lg"
          src={LogoutIcon}
          alt=""
        />
        <button
          onClick={signOutHandler }
          className="hover:text-white font-GaretRegular"
        >
          Log Out
        </button>
      </div>
    </div>
  );
}

export default SideBar;
