import Header from '../components/Header/Header';
import Home from '../pages/Home';
import SignUp from '../components/SignUp/SignUp';
import SignIn from '../components/SignIn/SignIn';
import Generator from '../components/Generator/Generator';
import Help from '../pages/Help/Help'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import ProtectedRoute from './ProtectedRoute';

function UserRouter() {
  const location = useLocation();
  const isInsideAdminRouter = location.pathname.startsWith('/admin');
  const navigate = useNavigate()
  

  useEffect(() => {
  }, [location]);

  
  return (
    <>
        {!isInsideAdminRouter && <Header />}
            <Routes>
                <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="/signup" element={<ProtectedRoute><SignUp /></ProtectedRoute>} />
                <Route path="/signin" element={<ProtectedRoute><SignIn/></ProtectedRoute>} />
                <Route path='/generator' element={<ProtectedRoute><Generator /></ProtectedRoute>} />
                <Route path='/help' element={<ProtectedRoute><Help /></ProtectedRoute>} />
            </Routes>
            {!isInsideAdminRouter && <Footer/> }
    </>
  )
}

export default UserRouter;