export const monthData = {
  series: [
    {
      name: "Visitors 01",
      data: [300, 50, 532, 46, 474, 253, 600],
    },
    {
      name: "Visitors 02",
      data: [200, 350, 132, 446, 274, 353, 100],
    },
  ],
  categories: [
    new Date().getDate() - 6,
    new Date().getDate() - 5,
    new Date().getDate() - 4,
    new Date().getDate() - 3,
    new Date().getDate() - 2,
    new Date().getDate() - 1,
    new Date().getDate(),
  ],
};

export const hourData = {
  series: [
    {
      name: "Visitors 01",
      data: [400, 250, 332, 106, 74, 53, 200],
    },
    {
      name: "Visitors 02",
      data: [300, 350, 382, 200, 60, 343, 170],
    },
  ],
  categories: [
    new Date().getMinutes() - 6,
    new Date().getMinutes() - 5,
    new Date().getMinutes() - 4,
    new Date().getMinutes() - 3,
    new Date().getMinutes() - 2,
    new Date().getMinutes() - 1,
    new Date().getMinutes(),
  ],
};

export const twentyFourData = {
  series: [
    {
      name: "Visitors 01",
      data: [300, 150, 432, 206, 274, 153, 600],
    },
    {
      name: "Visitors 02",
      data: [400, 450, 232, 46, 294, 453, 130],
    },
  ],
  categories: [
    new Date().getMinutes() - 6,
    new Date().getMinutes() - 5,
    new Date().getMinutes() - 4,
    new Date().getMinutes() - 3,
    new Date().getMinutes() - 2,
    new Date().getMinutes() - 1,
    new Date().getMinutes(),
  ],
};

export const weekData = {
  series: [
    {
      name: "Visitors 01",
      data: [100, 120, 232, 306, 274, 153, 200],
    },
    {
      name: "Visitors 01",
      data: [370, 320, 239, 656, 474, 433, 356],
    },
  ],
  categories: [
    new Date().getDate() - 6,
    new Date().getDate() - 5,
    new Date().getDate() - 4,
    new Date().getDate() - 3,
    new Date().getDate() - 2,
    new Date().getDate() - 1,
    new Date().getDate(),
  ],
};

export const yearData = {
  series: [
    {
      name: "Visitors 01",
      data: [140, 160, 282, 406, 74, 53, 390],
    },
    {
      name: "Visitors 02",
      data: [340, 260, 242, 499, 174, 543, 300],
    },
  ],

  categories: [
    new Date().getMonth() - 6,
    new Date().getMonth() - 5,
    new Date().getMonth() - 4,
    new Date().getMonth() - 3,
    new Date().getMonth() - 2,
    new Date().getMonth() - 1,
    new Date().getMonth(),
  ],
};

export const allData = {
  series: [
    {
      name: "Visitors 01",
      data: [440, 360, 282, 206, 474, 553, 290],
    },
    {
      name: "Visitors 02",
      data: [340, 460, 582, 256, 234, 543, 470],
    },
  ],

  categories: [
    new Date().getFullYear() - 6,
    new Date().getFullYear() - 5,
    new Date().getFullYear() - 4,
    new Date().getFullYear() - 3,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
  ],
};
