import SearchIcon from "../images/header/search.svg";
import BellIcon from "../images/header/bell.svg";
import Avatar from "../images/header/Avatar.png";
import React from "react";
function Header() {
  return (
    <div className="header-main w-full ">
      <div className="header-inner rounded-lg py-5 px-4 mt-6 flex justify-between  bg-white">
        <div className="flex lg:w-[30%] w-[40%] relative">
          <input
            className="border bg-[#f9f7f6]  w-full px-8 py-2 rounded-md outline-none font-GaretRegular"
            type="text"
            placeholder="Search"
          />
          <button type="submit" className="absolute top-[10px] right-2 p-0">
            <img src={SearchIcon} alt="" />
          </button>
        </div>
        <div className="w-[50%] flex justify-end items-center md:gap-5 gap-2">
          <img src={BellIcon} alt="" />
          <img src={Avatar} alt="" />
          <div>
            <h2 className="text-xs font-medium tracking-wide font-garetExtraBold">
              Amanda Rhe
            </h2>
            <p className="text-[#898989] text-xs font-GaretRegular">
              amanda.rhe@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
