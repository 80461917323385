import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import AdminRouter from './router/adminRouter';
import UserRouter from './router/userRouter';


function App() {
 
  return (
      <div className="container-fluid gx-0">
        <Router>
            <AdminRouter/>
            <UserRouter/>  
        </Router>
    </div>
  );
}

export default App;
