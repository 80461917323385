import React, { useEffect } from "react";
import Chart from "react-apexcharts";

function Graph({ series, categories }) {
  // console.log("series => ", series);
  return (
    <>
      <Chart
        id="area-datetime"
        type="area"
        width={"100%"}
        height={400}
        series={series?.map((item) => {
          return { name: item?.name, data: item?.data };
        })}
        options={{
          colors: ["#988171", "rgb(200,200,200)"],
          xaxis: {
            categories: categories,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            show: true,
          },
          legend: {
            show: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.8,
              opacityTo: 0.4,
              stops: [0, 100],
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            fontFamily: "GaretRegular, sans-serif",
          },
        }}
      />
    </>
  );
}

export default Graph;
