import React from "react";
import Vector from "../../images/dashboard/welcome-img.png";

function AnalyticsEngine() {
  return (
    <div className="xl:mt-20 mt-5 2xl:flex block bg-[#E5E7EBA6] xl:h-[192px] relative rounded-2xl xl:px-10 py-4 px-3">
      <div className="col">
        <div className="h-full flex flex-col justify-center">
          <h2 className="text-[42px] font-medium gradient-text font-garetExtraBold">
            Analytics Engine
          </h2>
          <p className="max-w-[664px] text-atomic-text-primary tracking-wide font-GaretRegular">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make.
          </p>
        </div>
      </div>
      <div className="col">
        <img
          className="xl:absolute bottom-0 right-10 mx-auto"
          src={Vector}
          alt=""
        />
      </div>
    </div>
  );
}

export default AnalyticsEngine;
