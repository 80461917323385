import "./Header.css";
import logo from "../../assets/imgs/Atomic-Interior-Logo.svg";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <header className="main-header-sec">
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-md-4 d-none d-sm-none d-md-block">
            <div className="logo">
              <Link to='/'>
                <img src={logo} alt="Atomic Interiors Logo" className="header-logo" />
              </Link>
            </div>
          </div>
          <div className="col-md-8 d-none d-sm-none d-md-block">
            <div className="main-nav-bar">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid justify-content-end">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className=" navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    
                      <li className="nav-item">
                        <a className="nav-link" href="#personalize">
                          Why Atomic Interior
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#commerce">
                          How it works
                        </a>
                      </li>
                    </ul>
                    <div className="d-flex gap-2 login-sec">
                      <Link to='signup' className="text-decoration-none btn-sign-up">Sign Up</Link>
                      <Link to='signin' className="text-decoration-none btn-sign-in text-white">Sign In</Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light d-md-none">
              <div className="container-fluid">
                <Link className="navbar-brand" to='/'>
                  <img src={logo} alt="Atomic Interiors Logo" className="header-logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="mobileNavToggle collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            aria-current="page"
                            href="#personalize"
                          >
                            Features
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#personalize">
                            Why Atomic Interior
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="#">
                            How it works
                          </Link>
                        </li>
                      </ul>
                  <form className="d-flex gap-2 login-sec">
                      <Link to='signup' className="text-decoration-none btn-sign-up">Sign Up</Link>
                      <Link to='signin' className="text-decoration-none btn-sign-in">Sign In</Link>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
