import Header from "../components/Header";
import SideBar from "../components/SideBar";
import AiDesignPlatform from "../components/ai-design-tool/AI-Design-Platform";
import DesignMySpace from "../components/ai-design-tool/Design-My-Space";
// import GetStarted from "../components/ai-design-tool/Get-Started";
import React from "react";
function AiDesignTool() {
  return (
    <section className="w-full bg-atomic-body-bg">
      <div className="flex ">
        <div className="col 2xl:w-[15%] max-w-[287px]  xl:w-[20%] md:w-[25%] w-[30%] ">
          <SideBar />
        </div>
        <div className="col 2xl:w-[85%]  xl:w-[80%] md:w-[75%] w-[70%] 2xl:px-6 px-3 overflow-y-auto h-screen">
          <Header />
          <AiDesignPlatform />
          {/* <GetStarted /> */}
          <DesignMySpace />
        </div>
      </div>
    </section>
  );
}

export default AiDesignTool;
