import React from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import AnalyticsEngine from "../components/analytics/AnalyticsEngine";
import ChartSec from "../components/analytics/ChartsSec";

function AdminAnalytics() {
  return (
    <section className="w-full bg-atomic-body-bg">
      <div className="flex min-h-screen">
        <div className="col 2xl:w-[15%] max-w-[287px]  xl:w-[20%] md:w-[25%] w-[30%] ">
          <SideBar />
        </div>
        <div className="col 2xl:w-[85%]  xl:w-[80%] md:w-[75%] w-[70%] 2xl:px-6 px-3 overflow-y-auto h-screen">
          <Header />
          <AnalyticsEngine />
          <ChartSec />
        </div>
      </div>
    </section>
  );
}

export default AdminAnalytics;
