import { useState } from "react";
import LineChart from "./ChartSection";
import { UserData } from "../../Data";
import FileIcon from "../../images/dashboard/file.svg";
import ArrowIcon from "../../images/dashboard/arrow.svg";
import React from "react";

function Analytics() {
  const apiDocumentation = [
    {
      id: 1,
      title: "API Documentation CC1",
      type: "All Type",
      icon: FileIcon,
    },
    {
      id: 2,
      title: "API Documentation CC1",
      type: "All Type",
      icon: FileIcon,
    },
    {
      id: 3,
      title: "API Documentation CC1",
      type: "All Type",
      icon: FileIcon,
    },
  ];

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
      {
        label: "Visitors Gained",
        data: UserData.map((data) => data.visitors),
      },
    ],
  });

  return (
    <div className="analytics mb-10">
      <h2 className="mb-6 text-atomic-text-secondary font-medium text-2xl font-garetExtraBold">
        Analytics
      </h2>
      <div className="xl:flex block gap-6 ">
        <div className="xl:w-[50%] w-full py-2 px-4 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
          <LineChart chartData={userData} />
        </div>
        <div className="xl:w-[50%] w-full bg-white rounded-xl border-[#E5E7EBA6] py-6  designCard">
          <div className="flex justify-between border-b-[1px] border-[#E5E7EBA6] px-7 pb-3">
            <div>
              <h3 className="text-lg font-medium text-atomic-text-secondary font-garetExtraBold">
                API Documentation
              </h3>
            </div>
            <div className="font-GaretRegular">
              <select>
                <option>Descending</option>
                <option>Ascending</option>
              </select>
            </div>
          </div>
          <div className="p-10 flex flex-col gap-5">
            {apiDocumentation.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex border border-[#E5E7EBA6] justify-between items-center rounded-lg p-3 bg-[#fdfcfb]"
                >
                  <div className="flex gap-4 items-center">
                    <div className="apiIcon">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="apiInfo">
                      <h2 className="text-sm font-semibold text-[#111827] font-garetExtraBold">
                        {item.title}
                      </h2>
                      <span className="text-[10px] text-[#6B7280] font-GaretRegular">
                        {item.type}
                      </span>
                    </div>
                  </div>
                  <div className="apiBtn">
                    <button>
                      <img src={ArrowIcon} alt="" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
