import './Help.css';
import emailjs from '@emailjs/browser';
import { useEffect, useState, useRef } from 'react';
import logo from '../../assets/imgs/Atomic-Interior-Logo.svg';
const Help = () => {
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("");
    const [userMessage, setUserMessage] = useState("");

    const form = useRef();
    const handleSubmit = (event) => {
        event.preventDefault();
        emailjs.sendForm('service_xvodd4m', 'template_gqrnyrk', form.current, 'ralpRif2RMF8HtrVf')
        .then((result) => {
            //console.log(result.text);
            alert("Message successfully send");
        }, (error) => {
            //console.log(error.text);
            alert('Error');
        });

        // clear input value
        setUserName("");
        setUserEmail("");
        setUserMessage("");
      }

      useEffect(()=> {
        window.scrollTo(0,0)
    },[]);

    return (
        <section className='main-help-sec'>
            <div className="container-fuild">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                        <div className="help-box">
                            <div className='logo'>
                                <img src={logo} alt='Atomic Interiors Logo' className='img-fluid pb-5 mx-3' />
                            </div>
                            <form ref={form} onSubmit={handleSubmit}>
                            <div className="mb-3">
                                    <label htmlFor="userName" className="form-label">User Name</label>
                                    <input type="text" onChange={(e) => setUserName(e.target.value)} value={userName} className="form-control" id="userName" name="from_name" placeholder="user name" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="userEmail" className="form-label">Email</label>
                                    <input type="email" onChange={(e) => setUserEmail(e.target.value)} value={userEmail} className="form-control" id="userEmail" name="user_email" placeholder="name@example.com" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="userMessage" className="form-label">Message</label>
                                    <textarea type="text" onChange={(e) => setUserMessage(e.target.value)} value={userMessage} className="form-control" id="userMessage" name="message" placeholder="enter your message" />
                                </div>
                                <button type="submit" value="Send" className="btn btn-submit text-white">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      )
}

export default Help;