import React, { useState } from "react";
import LineChart2 from "./LineChart2";
import Graph from "../dashboard/Chart";
import {
  allData,
  hourData,
  monthData,
  twentyFourData,
  weekData,
  yearData,
} from "../../constant/ConstantAnalytics";
import BarChart from "./BarChart";
import PieChart from "./PieChart";

function ChartSec() {
  const [selectedBtn, setSelectedBtn] = useState("1M");
  const btnArray = [
    {
      id: 1,
      btn: "1H",
    },
    {
      id: 2,
      btn: "24H",
    },
    {
      id: 3,
      btn: "1W",
    },
    {
      id: 4,
      btn: "1M",
    },
    {
      id: 5,
      btn: "1Y",
    },
    {
      id: 6,
      btn: "ALL",
    },
  ];
  const handleChartBtn = (btn) => {
    btnArray.forEach((item) => {
      if (item.btn === btn) {
        setSelectedBtn(btn);
      }
    });
  };
  const filterDates =
    selectedBtn === "1H"
      ? hourData
      : selectedBtn === "24H"
      ? twentyFourData
      : selectedBtn === "1W"
      ? weekData
      : selectedBtn === "1M"
      ? monthData
      : selectedBtn === "1Y"
      ? yearData
      : allData;
  return (
    <>
      <div className="lg:flex block gap-6">
        <div className="my-10 lg:w-1/2 w-full p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <h2 className="text-sm font-GaretRegular">No. of Visitors</h2>
              <p className="font-semibold text-xl font-garetExtraBold">
                11,900
              </p>
            </div>
            <div className=" flex gap-2 items-center">
              {btnArray.map((item, index) => {
                return (
                  <button
                    key={item.id}
                    className={` p-1 text-sm font-GaretRegular ${
                      selectedBtn === item.btn && "text-atomic-primary"
                    }`}
                    onClick={() => handleChartBtn(item.btn)}
                  >
                    {item.btn}
                  </button>
                );
              })}
            </div>
          </div>
          <Graph
            series={filterDates?.series}
            categories={filterDates?.categories}
          />
        </div>
        <div className="my-10 lg:w-1/2 w-full p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
          <LineChart2
            title={"Total Sales Generated By Tool"}
            series={[
              {
                name: "Visitors 01",
                data: [30, 50, 22, 46, 28, 25],
              },
            ]}
            categories={["10%", "20%", "30%", "40%", "50%", "60%", "70%"]}
          />
        </div>
      </div>
      <div className="my-10 lg:flex items-center block gap-8 p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
        <div className="lg:w-1/2 w-full">
          <LineChart2
            title={"Generations by Style Type"}
            series={[
              {
                name: "Visitors 01",
                data: [20, 30, 53, 40, 38, 45],
              },
              {
                name: "Visitors 02",
                data: [40, 50, 13, 54, 45, 32],
              },
            ]}
            categories={["10%", "20%", "30%", "40%", "50%", "60%", "70%"]}
          />
        </div>
        <div className="lg:w-1/2 w-full py-24 xl:px-24 px-10">
          <div className="flex gap-16 border-l">
            <ul className="pl-10 lg:pl-20 list-disc font-GaretRegular">
              <li>Modern:</li>
              <li>Chic:</li>
              <li>Art Deco:</li>
              <li>Retro:</li>
              <li>Bohemian:</li>
              <li>Tropical:</li>
              <li>Rustic:</li>
              <li>Mid Century:</li>
              <li>French Country:</li>
            </ul>
            <ul className="text-atomic-primary text-right font-GaretRegular">
              <li>15%</li>
              <li>25%</li>
              <li>10%</li>
              <li>05%</li>
              <li>12%</li>
              <li>17%</li>
              <li>21%</li>
              <li>21%</li>
              <li>13%</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="my-10 lg:flex items-center block gap-8 p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
        <div className="lg:w-1/2 w-full">
          <LineChart2
            title={"Generations by Space Type"}
            series={[
              {
                name: "Visitors 01",
                data: [20, 30, 53, 40, 38, 45],
              },
            ]}
            categories={["10%", "20%", "30%", "40%", "50%", "60%", "70%"]}
          />
        </div>
        <div className="lg:w-1/2 w-full py-24 xl:px-24 px-10">
          <div className="flex gap-16 border-l">
            <ul className="pl-10 lg:pl-20 list-disc font-GaretRegular">
              <li>Living Room:</li>
              <li>Kitchen:</li>
              <li>Bathroom:</li>
              <li>Dining Room:</li>
              <li>Game Room:</li>
              <li>Outdoor Patio:</li>
              <li>Entertainment Room:</li>
            </ul>
            <ul className="text-atomic-primary text-right font-GaretRegular">
              <li>15%</li>
              <li>25%</li>
              <li>10%</li>
              <li>05%</li>
              <li>12%</li>
              <li>17%</li>
              <li>21%</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:flex block gap-6">
        <div className="my-10 lg:w-1/2 w-full p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
          <BarChart
            title={"Total Sales Segmented by Product"}
            series={[
              {
                name: "Sales",
                data: [30, 50, 22, 46, 28, 25],
              },
            ]}
            categories={["10%", "20%", "30%", "40%", "50%", "60%"]}
          />
        </div>
        <div className="my-10 lg:w-1/2 w-full p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
          <BarChart
            title={"Total Sales Segmented by Product"}
            series={[
              {
                name: "Sales",
                data: [30, 50, 22, 46, 28, 25],
              },
            ]}
            categories={["10%", "20%", "30%", "40%", "50%", "60%"]}
          />
        </div>
      </div>

      <div className="my-10 w-full p-5 bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
        <h2 className="font-garetExtraBold font-medium text-lg">
          Visitors By Location
        </h2>
        <div className="lg:flex block justify-between">
          <div className="lg:w-1/2 w-full">
            <PieChart />
          </div>
          <div className="lg:w-1/2 w-full max-w-[500px] lg:mt-0 mt-10 w-full flex flex-col justify-center">
            <div className="flex lg:gap-10 justify-between">
              <div className="border-l px-10 mb-5 w-[200px]">
                <h4 className="text-[#9CA3AF] text-base font-medium font-GaretRegular">
                  Las Vegas
                </h4>
                <h2 className="text-2xl font-bold font-garetExtraBold">55%</h2>
              </div>
              <div className="border-l px-10 mb-5 w-[200px]">
                <h4 className="text-[#9CA3AF] text-base font-medium font-GaretRegular">
                  New York
                </h4>
                <h2 className="text-2xl font-bold font-garetExtraBold">15%</h2>
              </div>
            </div>
            <div className="flex lg:gap-10 justify-between">
              <div className="border-l px-10 mb-5 w-[200px]">
                <h4 className="text-[#9CA3AF] text-base font-medium font-GaretRegular">
                  Chicago
                </h4>
                <h2 className="text-2xl font-bold font-garetExtraBold">08%</h2>
              </div>
              <div className="border-l px-10 mb-5 w-[200px]">
                <h4 className="text-[#9CA3AF] text-base font-medium font-GaretRegular">
                  Others
                </h4>
                <h2 className="text-2xl font-bold font-garetExtraBold">10%</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartSec;
