import React from "react";
import Accordion from "../accordion/Accordion";

function Resources() {
  return (
    <div className="resourcesSec my-10">
      <div className="bg-[#f3f2f2] px-6 py-3 rounded-md">
        <h2 className="font-GaretRegular">Resources</h2>
      </div>
      <Accordion />
    </div>
  );
}

export default Resources;
