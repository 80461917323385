import { Link } from 'react-router-dom';
import { desiredStyle, roomType, designMode } from '../../constants';
import uploadImage from '../../assets/imgs/uploadImage.png';
import wing from '../../assets/imgs/wing.png';
import './Generator.css';

const Generator = () => {
    
  return (
    <section className='main-generator-sec'>
        <div className='main-generator-box'>
            <div className="container-fuild">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="generator-box">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='main-form-head'>
                                        <h1>Design My Space</h1>
                                        <p>From home renovations to commercial projects. Atomic Interior is the perfect AI-powered design assistant in your design process.</p>
                                    </div>
                                </div>
                            </div>
                            <form>
                                <div className='main-form-box'>
                                    <div className='row'>
                                        <div className='col-12 col-md-12 col-lg-6'>
                                            <div className='form-input-box'>
                                                <select className="form-select form-select-lg mb-3 border" aria-label=".form-select-lg">
                                                    <option selected>Pick Your Desired Style</option>
                                                    {
                                                        desiredStyle.map((dstyle,index) => {
                                                            return <option value={dstyle.value} key={index}>{dstyle.value}</option>                        
                                                        })
                                                    }
                                                </select>
                                                <select className="form-select form-select-lg mb-3 border" aria-label=".form-select-lg">
                                                    <option selected>What type of room is this?</option>
                                                        {
                                                            roomType.map((roomtype, index) => {
                                                                return <option value={roomtype.value} key={index}>{roomtype.value}</option>
                                                            })
                                                        }
                                                </select>
                                                <select className="form-select form-select-lg border " aria-label=".form-select-lg">
                                                    <option selected>Choose your design mode</option>
                                                        {
                                                            designMode.map((designmode, index) => {
                                                                return <option value={designmode.value} key={index}>{designmode.value}</option>
                                                            })
                                                        }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-12 col-lg-6'>
                                            <div className='form-img-box text-center border mt-sm-3 mt-lg-0'>
                                                {/* <img src={uploadImage} alt='upload' className='pt-2 img-fluid' /> */}
                                                <label htmlFor="formFileLg" className="form-label">
                                                    <img src={uploadImage} alt='upload' className='img-fluid' /> <br/>
                                                    <span className='upload-box'>Upload </span>or drop an image here <br />
                                                    <input className="form-control form-control-lg" id="formFileLg" type="file" hidden />
                                                    <span className='img-type'>Jpeg, jpg, png, tiff</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-items-center'>
                                        <Link to={'https://www.atomicinterior.com/signin'} className="btn btn-design-space mt-3 mx-auto text-white" target="_blank">Design My Space!</Link>
                                    </div>
                                </div>
                                {/* <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg">
                                    <option selected>Pick Your Desired Style</option>
                                {
                                    desiredStyle.map((dstyle,index) => {
                                        return <option value={dstyle.value} key={index}>{dstyle.value}</option>                        
                                    })
                                }
                                </select>
                                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg">
                                    <option selected>What type of room is this?</option>
                                    {
                                        roomType.map((roomtype, index) => {
                                            return <option value={roomtype.value} key={index}>{roomtype.value}</option>
                                        })
                                    }
                                </select>
                                <select className="form-select form-select-lg" aria-label=".form-select-lg">
                                    {
                                        designMode.map((designmode, index) => {
                                            return <option value={designmode.value} key={index}>{designmode.value}</option>
                                        })
                                    }
                                </select> */}
                                {/* <div>
                                    <label htmlFor="formFileLg" className="form-label"></label>
                                    <input className="form-control form-control-lg" id="formFileLg" type="file" />
                                </div> */}
                                {/* <Link to={'https://www.atomicinterior.com/signin'} className="btn btn-design-space mt-3 w-50 mx-auto text-white" target="_blank">Design My Space!</Link> */}
                            </form>
                        </div>
                    </div>
                    {/* <div className='col-md-6 col-sm-6'>
                        <div className='generator-form-intruction'>
                            <h3>Get Started</h3>
                            <p> 1. Upload a photo of your space and click submit <br />
                                2. If there's anything you like, crop the item and search for it<br />
                                3. Scroll through the products and setup your new space!<br />
                            </p>
                        </div>
                    </div> */}
                    <div className="col-sm-12 col-lg-6">
                        <div className='generator-form-intruction'>
                            {/* <h3>Get Started</h3>
                            <p> 1. Upload a photo of your space and click submit <br />
                                2. If there's anything you like, crop the item and search for it<br />
                                3. Scroll through the products and setup your new space!<br />
                            </p> */}
                            <div className='text-center'>
                                <img src={wing} alt='AI design' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Generator;