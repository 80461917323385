import Header from "../components/Header";
import SideBar from "../components/SideBar";
import APIConfig from "../components/api-configuration/APIConfig";
import Resources from "../components/api-configuration/Resources";
import React from "react";

function APIConfiguration() {
  return (
    <section className="w-full bg-atomic-body-bg">
      <div className="flex min-h-screen">
        <div className="col 2xl:w-[15%] max-w-[287px] xl:w-[20%] md:w-[25%] w-[30%] ">
          <SideBar />
        </div>
        <div className="col 2xl:w-[85%]  xl:w-[80%] md:w-[75%] w-[70%] 2xl:px-6 px-3 overflow-y-auto h-screen">
          <Header />
          <APIConfig />
          <Resources />
        </div>
      </div>
    </section>
  );
}

export default APIConfiguration;
