import React from "react";
import Chair from "../../images/dashboard/chair.png";
import FileIcon from "../../images/dashboard/fileUploadIcon.png";

import {
  desiredStyle,
  roomType,
  designMode,
} from "../../constant/designSpaceForm";

const DesignMySpace = () => {
  return (
    <div className="xl:flex block justify-between items-center bg-white py-10 my-5 2xl:px-32 px-16 rounded-3xl designCard2">
      <div>
        <h2 className="text-[#121212] font-extrabold text-6xl  leading-[72px] font-garetExtraBold">
          Design my space
        </h2>
        <p className="text-lg font-light leading-[30px] max-w-[530px] mb-6 font-GaretRegular">
          From home renovations to commercial projects. Atomic Interior is the
          perfect AI-powered design assistant in your design process.
        </p>
        <form className="flex flex-col justify-center items-center">
          <div className="md:flex block gap-4 md:mb-4 mb-0">
            <div className="flex flex-col gap-4 md:w-1/2 w-full">
              <select className="bg-[#F3F2F2] px-2 py-4 text-[#4D4D4D] text-sm rounded-lg outline-none appearance-none selectBg font-GaretRegular">
                <option>Pick your style</option>
                {desiredStyle.map((item, index) => {
                  return <option key={index}>{item.value}</option>;
                })}
              </select>
              <select className="bg-[#F3F2F2] px-2 py-3 text-[#4D4D4D] text-sm rounded-lg outline-none appearance-none selectBg font-GaretRegular">
                <option>What type of room is it?</option>
                {roomType.map((item, index) => {
                  return <option key={index}>{item.value}</option>;
                })}
              </select>
              <select className="bg-[#F3F2F2] px-2 py-3 text-[#4D4D4D] text-sm rounded-lg outline-none appearance-none selectBg font-GaretRegular">
                <option>Choose your design mode</option>
                {designMode.map((item, index) => {
                  return <option key={index}>{item.value}</option>;
                })}
              </select>
            </div>
            <div className="md:w-1/2 w-full">
              <label
                htmlFor="uploadfile-btn"
                className=" h-[220px] rounded-lg w-full bg-[#F3F2F2] flex flex-col justify-center p-10 cursor-pointer"
              >
                <div className="flex flex-col items-center">
                  <img src={FileIcon} className="w-20" alt="" />

                  <span className="text-atomic-primary text-sm block text-center font-GaretRegular">
                    Upload
                    <span className="pl-1 text-[#4D4D4D]">
                      or drop an image here
                    </span>
                  </span>
                  <span className="text-black text-xs text-center font-GaretRegular">
                    JPEG, JPG, PNG,..
                  </span>
                </div>
              </label>
              <input
                type="file"
                id="uploadfile-btn"
                accept=".jpeg, .jpg, .png"
                hidden
              />
            </div>
          </div>
          <button
            className=" bg-atomic-primary font-garetExtraBold text-white rounded-[10px] rounded-bl-[15px] mt-7"
            type="submit"
          >
            Design My Space
          </button>
        </form>
      </div>
      <div>
        <img className="mx-auto" src={Chair} alt="" />
      </div>
    </div>
  );
};

export default DesignMySpace;
