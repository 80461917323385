import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

function AdminProtected({children}) {
      
    const getUser = localStorage.getItem('userEmail')
    const [isAuthenticated, setIsAuthenticated] = useState(false);
 


    if(!getUser){
     
        return  <Navigate to={'/signin'} />
    }
    
    return children
    
}

export default AdminProtected