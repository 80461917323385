import './Home.css';
import { useEffect } from 'react';
import dashboard from '../assets/imgs/dashboard.png';
import blogOneImg from '../assets/imgs/furniture-home-goods.png';
import blogtwoImg from '../assets/imgs/real-estate-and-developers.png';
import blogthreeImg from '../assets/imgs/interior-designers-architects.png';
import desingIcon from '../assets/imgs/design-icon.png';
import shopIcon from '../assets/imgs/shop-icon.png';
import analyzeIcon from '../assets/imgs/analyze-icon.png';
import enhanceImg from '../assets/imgs/enhance.png';
import convertoImg from '../assets/imgs/convert.png';
import analyzeImg from '../assets/imgs/analyze.png';
import improveImg from '../assets/imgs/improve.png';
import growImg from '../assets/imgs/grow.png';
import { Link } from 'react-router-dom';

const  Home = () => {
  useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      {/* Hero Section Start */}
      <section className='main-hero-sec'>
        <div className='container-fluid'>
          <div className="row">
            <div className="col-md-12">
              <div className='hero-container text-center'>
                <h1>A.I. commerce design platform</h1>
                <p>Delight your customers and grow your business</p>
                {/* <button type='button' className='btn text-white btn-get-started'>Get Started</button> */}
                <Link to={'/signup'} className='btn text-white btn-get-started'> Get Started</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero Section End */}
      {/* Dashboard Section Start */}
      <section className='main-dashboard-sec'>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-md-12">
                      <div className="dashboard-container">
                          <div className='dashboard-box text-center'>
                            <img src={dashboard} alt='Dashboard' className='dashboard-img img-fluid' />
                          </div>
                          <p className='text-white' id='personalize'>Personalize the customer experience, boost sales, and enhance inventory planning and forecasting.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* Dashboard Section End */}

      {/* Atomic Section Start */}
      <section className='main-atomic-sec'>
        <div className="container-fluid">
          <div className='row'>
            <div className="col-lg-12">
              <div className="row bg-white align-items-center atomic-row-content">
                <div className='col-md-6'>
                  <div className='left-atomic-text'>
                    <h2>Enhance.</h2>
                    <h3>Enhance.</h3>
                  </div>
                  <img src={enhanceImg} alt='Enhance' className='img-fluid' />
                </div>
                <div className='col-md-6'>
                  <div className='left-atomic-content'>
                    <h3>Enhance Customer Experience</h3>
                    <p>The platform can be leveraged as a part of the e-commerce and in-store experience to enable omnichannel retailing</p>
                    {/* <button type='button' className='btn get-started'>Get Started</button> */}
                    <Link to={'/signup'} className='btn get-started'> Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-lg-12">
              <div className="row bg-white align-items-center atomic-row-content atomic-row-right-content">
                <div className='col-md-6'>
                <div className='left-atomic-content'>
                    <h3>Increase shopping cart</h3>
                    <p>Behind the scenes, the tool is gathering real time data (first party data) and tracking how the consumer is using the product what styles, preferences, etc they are clicking on. </p>
                    {/* <button type='button' className='btn get-started'>Get Started</button> */}
                    <Link to={'/signup'} className='btn get-started'> Get Started</Link>
                  </div>
                </div>
                <div className='col-md-6'>
                <div className='left-atomic-text'>
                    <h2 className='text-end'>Convert.</h2>
                    <h3 className='text-end'>Convert.</h3>
                  </div>
                  <img src={convertoImg} alt='Enhance' className='img-fluid' />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-lg-12">
              <div className="row bg-white align-items-center atomic-row-content">
                <div className='col-md-6'>
                  <div className='left-atomic-text'>
                    <h2>Analyze.</h2>
                    <h3>Analyze.</h3>
                  </div>
                  <img src={analyzeImg} alt='Enhance' className='img-fluid' />
                </div>
                <div className='col-md-6'>
                  <div className='left-atomic-content'>
                    <h3>Real Time Business Intelligence</h3>
                    <p>Behind the scenes, the tool is gathering real time data and tracking how the consumer is using the product (what styles, preferences, etc) they are clicking on. </p>
                    {/* <button type='button' className='btn get-started'>Get Started</button> */}
                    <Link to={'/signup'} className='btn get-started'> Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-lg-12">
              <div className="row bg-white align-items-center atomic-row-content atomic-row-right-content">
                <div className='col-md-6'>
                <div className='left-atomic-content'>
                    <h3>Improve Forecast and Planning</h3>
                    <p>Having first hand data and insights into the trends and analysis will enable greater forecasting and planning.</p>
                    {/* <button type='button' className='btn get-started'>Get Started</button> */}
                    <Link to={'/signup'} className='btn get-started'> Get Started</Link>
                  </div>
                </div>
                <div className='col-md-6'>
                <div className='left-atomic-text'>
                    <h2 className='text-end'>Improve.</h2>
                    <h3 className='text-end'>Improve.</h3>
                  </div>
                  <img src={improveImg} alt='Enhance' className='img-fluid' />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-lg-12">
              <div className="row bg-white align-items-center atomic-row-content">
                <div className='col-md-6'>
                  <div className='left-atomic-text'>
                    <h2>Grow.</h2>
                    <h3>Grow.</h3>
                  </div>
                  <img src={growImg} alt='Enhance' className='img-fluid' />
                </div>
                <div className='col-md-6'>
                  <div className='left-atomic-content'>
                    <h3>Increase Customer Retention</h3>
                    <p>Consumers have a reason to continuously shop, as they can see and understand how their spaces comes to life.</p>
                    {/* <button type='button' className='btn get-started'>Get Started</button> */}
                    <Link to={'/signup'} className='btn get-started'> Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Atomic Section End */}

      {/* Commerce Atomic Start */}
      <section className='main-commerce-atomic-sec' id='commerce'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className='commerce-atomic-head text-white'>
                <h2 className='text-center'>Make commerce Atomic</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className='commerce-atomic-boxes text-white text-center'>
                <img src={desingIcon} alt='Design' className='pb-3 mx-auto' />
                <h3 className='pb-2'>Design</h3>
                <p>Consumers upload their room and select their style.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className='commerce-atomic-boxes text-white text-center'>
                <img src={shopIcon} alt='Design' className='pb-3 mx-auto' />
                  <h3 className='pb-2'>Shop</h3>
                  <p>Consumers can buy the entire designed room or individual pieces</p>
                </div>
            </div>
            <div className="col-md-4">
              <div className='commerce-atomic-boxes text-white text-center'>
                <img src={analyzeIcon} alt='Design' className='pb-3 mx-auto' />
                <h3 className='pb-2'>Analyze</h3>
                <p>Understand your customer and their buying habits.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Commerce Atomic End */}

      {/* Industries Section Start */}
      <section className='main-industries-sec'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className='industries-head'>
                <h2 className='text-center pb-4'>Industries we serve</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className='industries-boxes text-center'>
                <img src={blogOneImg} alt='Design' className='pb-3 img-fluid' />
                <h3 className='pb-2'>Furniture & Home Goods</h3>
                <p>Ideal for furniture and home goods businesses looking to create a more personalized shopping experience for their customers, increase site conversion to boost sales and plans for the future.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className='industries-boxes text-center'>
                <img src={blogtwoImg} alt='Design' className='pb-3 img-fluid' />
                  <h3 className='pb-2'>Real Estate And Developers</h3>
                  <p>Real estate agents can use Atomic Interior to do virtual staging for any space, allowing potential home buyers to see the potential of a space.</p>
                </div>
            </div>
            <div className="col-md-4">
              <div className='industries-boxes text-center'>
                <img src={blogthreeImg} alt='Design' className='pb-3 img-fluid' />
                <h3 className='pb-2'>Interior Designers & Architects</h3>
                <p>Visualize design ideas and communicate various designs effectively to your clients. Streamline your workflow and save time. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Industries Section End */}

      {/* Innovative Section Start */}
      <section className='main-innovative-sec'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className='innovative-head text-center text-white'>
                <h2 className='text-center'>Want to be innovative?</h2>
                <p>Let's work together!</p>
                {/* <button type='button' className='btn tell-me-more'>Tell us more</button> */}
                <Link to={'/signup'} className='btn text-white tell-me-more'>Tell us more</Link>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Innovative Section End */}
    </>
  )
}

export default Home;