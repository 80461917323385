import RoomImg from "../../images/dashboard/room-img.png";

function Tool() {
  const styleCards = [
    {
      id: 1,
      style: "Style 01",
      room: "Bed Room",
      status: "Redesign",
      furnish: "Existing Furniture",
      image: { RoomImg },
    },
    {
      id: 2,
      style: "Style 02",
      room: "Bed Room",
      status: "Redesign",
      furnish: "Existing Furniture",
      image: { RoomImg },
    },
    {
      id: 3,
      style: "Style 03",
      room: "Bed Room",
      status: "Redesign",
      furnish: "Existing Furniture",
      image: { RoomImg },
    },
  ];
  return (
    <div className="my-10">
      <h2 className="mb-6 text-[#1d2435] font-medium text-2xl font-garetExtraBold">
        Ai Tool Design
      </h2>
      <div className="row xl:flex md:block block justify-between">
        {styleCards.map((item) => {
          return (
            <div
              key={item.id}
              className="designCard col bg-[#f7f7f7] w-full 2xl:max-w-[32%] max-w-[100%] flex justify-between items-center border-[#E5E7EBA6] px-6 py-5 2xl:mb-0 mb-4"
            >
              <div>
                <span className="text-[#373a3c] font-GaretRegular">
                  {item.style}
                </span>
                <h3 className="text-[#161616] text-2xl font-semibold pb-2 font-garetExtraBold">
                  {item.room}
                </h3>
                <div className="flex items-center gap-2">
                  <span className="text-atomic-primary font-medium text-xs py-2 pr-4 pl-6 bg-[#20202014] rounded-xl font-GaretRegular relative before:block before:absolute before:left-[9%] before:top-3 before:bg-atomic-primary before:w-[6px] before:h-[6px] before:rounded">
                    {item.status}
                  </span>
                  <span className="text-xs font-GaretRegular">
                    {item.furnish}
                  </span>
                </div>
              </div>
              <div>
                <img src={RoomImg} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Tool;
