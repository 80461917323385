import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

function ProtectedRoute({children}) {
      
    const getUser = localStorage.getItem('userEmail')
    const [isAuthenticated, setIsAuthenticated] = useState(false);
 


    if(getUser){
          
       
        
        return  <Navigate to={'/admin/dashboard'} />
    }
    return children

    
}

export default ProtectedRoute