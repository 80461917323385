import './Footer.css';
import logo from "../../assets/imgs/Atomic-Interior-Logo.svg";
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className='main-footer-sec'>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-2'></div>
          <div className="col-md-3">
            <div className='logo'>
              <Link to='/' onClick={()=>window.scroll(0,0)}>
                <img src={logo} className='footer-logo' alt='Footer Logo' />
              </Link>
            </div>
          </div>
          <div className="col-md-3">
            <div className="site">
              <h3>Site</h3>
              <ul className='navbar-nav'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='about-us'>About Us</Link>
                </li>
                {/* <li>
                  <Link>Features</Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
          <div className="support">
              <h3>Support</h3>
              <ul className='navbar-nav'>
                <li className="nav-item">
                  <Link to='help'>Help</Link>
                </li>
                <li>
                  <Link to='help'>Customer Support</Link>
                </li>
                <li>
                  <Link>Privacy Policy</Link>
                </li>
                <li>
                  <Link>Terms of Service</Link>
                </li>
                <li>
                  <Link>Security</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-3">
          <div className="social">
              <h3>Social</h3>
              <ul className='navbar-nav'>
                <li>
                  <Link>Facebook</Link>
                </li>
                <li>
                  <Link>Instagram</Link>
                </li>
                <li>
                  <Link>Pinterest</Link>
                </li>
                <li>
                  <Link>Youtube</Link>
                </li>
                <li>
                  <Link>Snapchat</Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer;