// import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

function BarChart({ series, categories, title }) {
  return (
    <>
      <Chart
        type="bar"
        width={"100%"}
        height={400}
        series={series?.map((item) => {
          return { name: item?.name, data: item?.data };
        })}
        options={{
          colors: ["#988171", "rgb(200,200,200)"],
          xaxis: {
            categories: categories,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          yaxis: {
            show: true,
            min: 0,
            max: 60,
            tickAmount: 6,
            labels: {
              formatter: function (value) {
                return value.toFixed(0) + "$";
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: title,
            style: {
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "garetExtraBold",
            },
          },
          legend: {
            show: false,
          },
          chart: {
            toolbar: {
              show: false,
            },
            fontFamily: "GaretRegular, sans-serif",
          },
        }}
      />
    </>
  );
}

export default BarChart;
