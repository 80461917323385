export const desiredStyle = [
    {
        key: 1,
        value: 'Baroque'
    }, {
        key: 2,
        value: 'Modern'
    }, {
        key: 3,
        value: 'Chic'
    }, {
        key: 4,
        value: 'Bohemian'
    }, {
        key: 5,
        value: 'Mid-Century Modern'
    }, {
        key: 6,
        value: 'Art Deco'
    }, {
        key: 7,
        value: 'Rustic'
    }, {
        key: 8,
        value: 'Farmhouse'
    }, {
        key: 9,
        value: 'French Country'
    }, {
        key: 10,
        value: 'Tron'
    }, {
        key: 11,
        value: 'Christmas'
    }, {
        key: 12,
        value: 'Cartoon'
    }, {
        key: 13,
        value: 'Tropical'
    }, {
        key: 14,
        value: 'Miami'
    }, {
        key: 15,
        value: 'Minimalist'
    }, {
        key: 16,
        value: 'Zen'
    }, {
        key: 17,
        value: 'Art Nouveu'
    }, {
        key: 18,
        value: 'Cyberpunk'
    }, {
        key: 19,
        value: 'Cyberpunk'
    }, {
        key: 20,
        value: 'Tribal'
    }, {
        key: 21,
        value: 'Medieval'
    }, {
        key: 22,
        value: 'Scandinavian'
    }
];

export const roomType = [
    {
        id: 1,
        value: 'Living Room'
    }, {
        id: 2,
        value: 'Dining Room'
    }, {
        id: 3,
        value: 'Kitchen'
    }, {
        id: 4,
        value: 'Bedroom'
    }, {
        id: 5,
        value: 'Master Bedroom'
    }, {
        id: 6,
        value: 'Patio'
    }, {
        id: 7,
        value: 'Dining Room'
    }, {
        id: 8,
        value: 'Game Room'
    }, {
        id: 9,
        value: 'Bathroom'
    }, {
        id: 10,
        value: 'Office'
    }, {
        id: 11,
        value: 'Family Room'
    }, {
        id: 12,
        value: 'Den'
    }, {
        id: 13,
        value: 'Garage'
    }, {
        id: 14,
        value: 'Gym'
    }, {
        id: 15,
        value: 'Art Studio'
    }, {
        id: 16,
        value: 'Yoga Studio'
    }, {
        id: 17,
        value: 'Pilates Studio'
    }, {
        id: 18,
        value: 'Library'
    }
];

export const designMode = [
    {
        value: 'Redesign My Room (Existing Furniture) [fast - 15s]'
    }, {
        value: 'Design Empty Room (Virtual Staging) [slow - 2m]'
    }, {
        value: 'Convert Room To Another Room Type [fast - 15s]'
    }
];