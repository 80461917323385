// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBC9Fr3MkW4QQPhbB1_Fjbpg6ER1DCE2dw",
  authDomain: "atomic-interior.firebaseapp.com",
  projectId: "atomic-interior",
  storageBucket: "atomic-interior.appspot.com",
  messagingSenderId: "668000364068",
  appId: "1:668000364068:web:3027f00c01f052b26a5ab1",
  databaseURL: "https://atomic-interior-default-rtdb.firebaseio.com",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth =getAuth(app)