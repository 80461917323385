import React, { useState } from "react";
import Graph from "./Chart";
import {
  allData,
  hourData,
  monthData,
  twentyFourData,
  weekData,
  yearData,
} from "../../constant/ConstantDashboard";

function ChartSec() {
  const [selectedBtn, setSelectedBtn] = useState("1M");
  const btnArray = [
    {
      id: 1,
      btn: "1H",
    },
    {
      id: 2,
      btn: "24H",
    },
    {
      id: 3,
      btn: "1W",
    },
    {
      id: 4,
      btn: "1M",
    },
    {
      id: 5,
      btn: "1Y",
    },
    {
      id: 6,
      btn: "ALL",
    },
  ];
  const handleChartBtn = (btn) => {
    btnArray.forEach((item) => {
      if (item.btn === btn) {
        setSelectedBtn(btn);
      }
    });
  };

  const filterDates =
    selectedBtn === "1H"
      ? hourData
      : selectedBtn === "24H"
      ? twentyFourData
      : selectedBtn === "1W"
      ? weekData
      : selectedBtn === "1M"
      ? monthData
      : selectedBtn === "1Y"
      ? yearData
      : allData;
  // console.log(new Date().toDateString());
  return (
    <div className="my-10 xl:w-full w-full bg-white rounded-xl border-[#E5E7EBA6] 2xl:mb-0 mb-3 designCard">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="text-sm font-GaretRegular">Visitors</h2>
          <p className="font-semibold text-xl font-garetExtraBold">11,900</p>
        </div>
        <div className=" flex gap-2 items-center">
          {btnArray.map((item, index) => {
            return (
              <button
                key={item.id}
                className={` p-1 text-sm font-GaretRegular ${
                  selectedBtn === item.btn && "text-atomic-primary"
                }`}
                onClick={() => handleChartBtn(item.btn)}
              >
                {item.btn}
              </button>
            );
          })}
        </div>
      </div>

      <Graph
        series={filterDates?.series}
        categories={filterDates?.categories}
      />
    </div>
  );
}

export default ChartSec;
