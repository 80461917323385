import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LiaCheckDoubleSolid } from "react-icons/lia";

const initialData = {
  todo: [
    {
      id: "task1",
      text: "Task 01",
      text1: "Task: Lorem Ipsum has been the industry's stand.",
      text2: "Task: Lorem Ipsum has been the industry's stand.",
      text3: "Task: Lorem Ipsum has been the industry's stand.",
      text4: "Task: Lorem Ipsum has been the industry's stand.",
    },
    {
      id: "task2",
      text: "Task 02",
      text1: "Task: Lorem Ipsum has been the industry's stand.",
      text2: "Task: Lorem Ipsum has been the industry's stand.",
      text3: "Task: Lorem Ipsum has been the industry's stand.",
      text4: "Task: Lorem Ipsum has been the industry's stand.",
    },
    {
      id: "task3",
      text: "Task 03",
      text1: "Task: Lorem Ipsum has been the industry's stand.",
      text2: "Task: Lorem Ipsum has been the industry's stand.",
      text3: "Task: Lorem Ipsum has been the industry's stand.",
      text4: "Task: Lorem Ipsum has been the industry's stand.",
    },
  ],
  done: [
    {
      id: "notes1",
      text: "Notes 01",
      text1:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make.",
    },
  ],
};

export default function Kanban() {
  const [tasks, setTasks] = useState(initialData);
  const [notes, setNotes] = useState([]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceListId = source.droppableId;
    const destinationListId = destination.droppableId;

    if (
      sourceListId === destinationListId &&
      source.index === destination.index
    ) {
      return;
    }

    // Update the state based on the drag-and-drop result
    const newTasks = { ...tasks };
    const [removed] = newTasks[sourceListId].splice(source.index, 1);
    newTasks[destinationListId].splice(destination.index, 0, removed);

    // Update the state with the new tasks
    setTasks(newTasks);

    // Update the selected tasks based on the new state
    const updatedSelectedTodo =
      selectedTodo && newTasks.todo.some((task) => task.id === selectedTodo)
        ? selectedTodo
        : "";
    setSelectedTodo(updatedSelectedTodo);

    const updatedSelectedDone =
      selectedDone && newTasks.done.some((task) => task.id === selectedDone)
        ? selectedDone
        : "";
    setSelectedDone(updatedSelectedDone);
  };

  const [selectedTodo, setSelectedTodo] = useState("");
  const [selectedDone, setSelectedDone] = useState("");

  const handleSelectTodo = (id) => {
    // Check if the clicked task is in the 'todo' list
    const todoTask = initialData.todo.find((task) => task.id === id);
    if (todoTask) {
      setSelectedTodo(id);
      setSelectedDone(""); // Deselect the task on the right side if any
    }
  };

  const handleSelectDone = (id) => {
    // Check if the clicked task is in the 'done' list
    const doneTask = initialData.done.find((task) => task.id === id);
    if (doneTask) {
      setSelectedDone(id);
      setSelectedTodo(""); // Deselect the task on the left side if any
    }
  };

  return (
    <div className="my-10">
      <div className="bg-[#f3f2f2] px-6 py-3 rounded-md flex">
        <h2 className="w-[50%] font-GaretRegular">To Do's</h2>
        <h2 className="w-[50%] font-GaretRegular">Notes</h2>
      </div>
      <div className="w-full lg:p-6 sm:p-0 mt-3 rounded-lg">
        <div className="grid grid-cols-2 gap-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="todo">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="max-w-[360px]"
                >
                  {tasks.todo.map((task, index) => (
                    <Draggable
                      draggableId={task.id}
                      index={index}
                      type={"task"}
                      key={task.id}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={task.id}
                          onClick={() => handleSelectTodo(task.id)}
                          className={`border-1 p-3 rounded-lg mb-2 bg-white relative todoCard ${
                            selectedTodo === task.id
                              ? "border-2 border-atomic-primary "
                              : "border-2 border-[#E5E7EBA6]"
                          }`}
                        >
                          <h2 className="font-semibold text-sm pb-3 font-garetExtraBold">
                            {task.text}
                          </h2>
                          <ul className="list-disc ml-5 text-xs text-[#5F5F5F] font-GaretRegular">
                            <li className="pb-3">{task.text1}</li>
                            {task.text2 && (
                              <li className="pb-3">{task.text2}</li>
                            )}
                            {task.text3 && (
                              <li className="pb-3">{task.text3}</li>
                            )}
                            {task.text4 && (
                              <li className="pb-3">{task.text4}</li>
                            )}
                          </ul>
                          <div
                            className={`w-5 h-5 border-1 rounded-full text-white flex items-center justify-center absolute top-3 right-3 icon ${
                              selectedTodo === task.id
                                ? "bg-atomic-primary"
                                : "bg-transparent"
                            } `}
                          >
                            {selectedTodo === task.id && (
                              <LiaCheckDoubleSolid />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="done">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="max-w-[360px]"
                >
                  {tasks.done.map((task, index) => (
                    <Draggable
                      key={task.id}
                      draggableId={task.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={task.id}
                          onClick={() => handleSelectDone(task.id)}
                          className={`border-1 p-3 rounded-lg mb-2 bg-white relative ${
                            selectedDone === task.id
                              ? "border-2 border-atomic-primary "
                              : "border-2 border-[#E5E7EBA6]"
                          }`}
                        >
                          <h2 className="font-semibold text-sm pb-3 font-garetExtraBold">
                            {task.text}
                          </h2>
                          <ul className="list-disc ml-5 text-xs text-[#5F5F5F] font-GaretRegular">
                            <li className="pb-3">{task.text1}</li>
                            {task.text2 && (
                              <li className="pb-3">{task.text2}</li>
                            )}
                            {task.text3 && (
                              <li className="pb-3">{task.text3}</li>
                            )}
                            {task.text4 && (
                              <li className="pb-3">{task.text4}</li>
                            )}
                          </ul>
                          <div
                            className={`w-5 h-5 border-1 rounded-full text-white flex items-center justify-center absolute top-3 right-3 ${
                              selectedDone === task.id
                                ? "bg-atomic-primary"
                                : "bg-transparent"
                            } `}
                          >
                            {selectedDone === task.id && (
                              <LiaCheckDoubleSolid />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}
