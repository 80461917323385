import React, { useEffect } from "react";
import Chart from "react-apexcharts";

function LineChart2({ series, categories, title }) {
  return (
    <>
      <Chart
        id="area-datetime"
        type="area"
        width={"100%"}
        height={400}
        series={series?.map((item) => {
          return { name: item?.name, data: item?.data };
        })}
        options={{
          colors: ["#988171", "rgb(200,200,200)"],
          xaxis: {
            categories: categories,
          },
          yaxis: {
            show: true,
            opposite: true,
            // forceNiceScale: true,
            min: 0,
            max: 60,
            // Pass an array of tick positions to display custom ticks on the Y-axis.
            tickAmount: 6,
            // Use the `labels` property to customize the labels shown on the Y-axis.
            labels: {
              formatter: function (value) {
                // Return the custom label you want to display for each tick value.
                return value.toFixed(0) + "$"; // This will add a '$' symbol before each value with two decimal places.
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: title,
            style: {
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "garetExtraBold",
            },
          },
          legend: {
            show: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.4,
              opacityTo: 0.1,
              stops: [0, 100],
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            fontFamily: "GaretRegular, sans-serif",
          },
        }}
      />
    </>
  );
}

export default LineChart2;
